// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    baseApiUrl: 'https://fu8e.space/local-gateway/app-identity/', //V2 Dev URL for Authentication
    CCM_API_URL:
      'https://fu8e.space/local-gateway/customer-complain-managment-service/',
    workspaceApiUrl: 'https://fu8e.space/local-gateway/app-services/', //V2 Dev URL for Workspace
    webSiteURL: 'https://fu8e.space/',
    downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
    notificationAPiUrl: 'https://pushcenter.fusionsuite.net/',
    stripePublishKey:
      'pk_test_51Mo4nSAmzvicoAcQr7cNb21GKFUZghB0RJLKWdLBOZnAO6Hh2lkgdwJ7MfFZR9ldBv4RlFo4pOOapiYnvoVgtQ9R001aXsaPul',

  //behzad testing envirnoment

  //   baseApiUrl: 'http://52.25.13.27:8005/', //V2 Dev URL for Authentication
  //   CCM_API_URL: 'http://52.25.13.27:8009/',
  //   workspaceApiUrl: 'http://52.25.13.27:8006/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://fu8e.space/',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   notificationAPiUrl: 'https://pushcenter.fusionsuite.net/',
  //Commting on request of Shehroz
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',

  // stripePublishKey:
  //   'pk_test_51Mo4nSAmzvicoAcQr7cNb21GKFUZghB0RJLKWdLBOZnAO6Hh2lkgdwJ7MfFZR9ldBv4RlFo4pOOapiYnvoVgtQ9R001aXsaPul',

  // Prod
//   baseApiUrl: 'https://identity.fusionsuite.net/', //V2 Production URL for Authentication,
//   CCM_API_URL:
//     'https://identity.fusionsuite.net/customer-complain-managment-service/',
//   workspaceApiUrl: 'https://fappservices.fusionsuite.net/', //V2 Production URL for Workspace
//   stripePublishKey:
//     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
//   webSiteURL: 'https://app.fusionsuite.net/',
//   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
//   notificationAPiUrl: 'https://pushcenter.fusionsuite.net/',

  //   staging
  //   baseApiUrl:
  //     'https://funky-toad-evolved.ngrok-free.app/local-gateway/app-identity/', //V2 Dev URL for Authentication
  //   CCM_API_URL:
  //     'https://funky-toad-evolved.ngrok-free.app/local-gateway/customer-complain-managment-service/',
  //   workspaceApiUrl:
  //     'https://funky-toad-evolved.ngrok-free.app/local-gateway/app-services/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://funky-toad-evolved.ngrok-free.app/',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
  //   staging
  // baseApiUrl: 'http://192.168.18.245:8005/',
  // CCM_API_URL: 'http://192.168.18.245:8009/',
  // workspaceApiUrl: 'http://192.168.18.245:8006/',
  // webSiteURL: 'https://funky-toad-evolved.ngrok-free.app/',
  // downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  // stripePublishKey:
  //   'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
  // Test Environment 1
  //   baseApiUrl: 'https://staging.fu8e.space/app-identity/', //V2 Dev URL for Authentication
  //   CCM_API_URL:
  //     'https://staging.fu8e.space/customer-complain-managment-service/',
  //   workspaceApiUrl: 'https://staging.fu8e.space/app-services/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://staging.fu8e.space',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
  // Stagging
  //   baseApiUrl: 'https://identity.cloudstaging.fu8e.space/', //V2 Dev URL for Authentication
  //   CCM_API_URL:
  //     'https://app.cloudstaging.fu8e.space/customer-complain-managment-service/',
  //   workspaceApiUrl: 'https://fappservices.cloudstaging.fu8e.space/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://staging.fu8e.space',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   notificationAPiUrl: 'https://pushcenter.fusionsuite.net/',
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
  //   stripePublishKey:
  //     'pk_test_51Mo4nSAmzvicoAcQr7cNb21GKFUZghB0RJLKWdLBOZnAO6Hh2lkgdwJ7MfFZR9ldBv4RlFo4pOOapiYnvoVgtQ9R001aXsaPul',

  // Test Environment 2
  //   baseApiUrl: 'https://10.0.0.240/app-identity/', //V2 Dev URL for Authentication
  //   CCM_API_URL: 'https://10.0.0.240/customer-complain-managment-service/',
  //   workspaceApiUrl: 'https://10.0.0.240/app-services/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://10.0.0.240/',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
  // Test Environment 3
  //   baseApiUrl: 'https://115.186.145.231/app-identity/', //V2 Dev URL for Authentication
  //   CCM_API_URL: 'https://115.186.145.231/customer-complain-managment-service/',
  //   workspaceApiUrl: 'https://115.186.145.231/app-services/', //V2 Dev URL for Workspace
  //   webSiteURL: 'https://115.186.145.231/',
  //   downloadImageApiUrl: 'https://outbound-io.fusioninspect.com/v2/bug/file/',
  //   stripePublishKey:
  //     'pk_live_51Mo4nSAmzvicoAcQU1fyBc64OTK4QGRB7LMhrKSZQnGywpKVpI8D3jNXmy5HhVaGxC6YjgHvnL6PH9NEMuylND2d00eW5aBZQE',
};

/*
 * For easier debugging in development mode, you can import the following filex
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
