// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'abbreviateNumber',
// })
// export class AbbreviateNumberPipe implements PipeTransform {
//   transform(value: number, includeDecimal: boolean): string {
//     if (value === null || value === undefined) {
//       return '';
//     }
//     if (includeDecimal) {
//       if (value < 1000) {
//         return value.toString();
//       } else if (value < 1000000) {
//         return (value / 1000).toFixed(1) + 'K';
//       } else if (value < 1000000000) {
//         return (value / 1000000).toFixed(1) + 'M';
//       } else {
//         return (value / 1000000000).toFixed(1) + 'B';
//       }
//     } else {
//       if (value < 1000) {
//         return value.toString();
//       } else if (value < 1000000) {
//         return (value / 1000).toFixed(1) + 'K';
//       } else if (value < 1000000000) {
//         return (value / 1000000).toFixed(1) + 'M';
//       } else {
//         return (value / 1000000000).toFixed(1) + 'B';
//       }
//     }
//   }
// }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber',
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(value: number, includeDecimal: boolean = true): string {
    if (value === null || value === undefined) {
      return '';
    }

    const abbreviate = (
      num: number,
      divider: number,
      suffix: string
    ): string => {
      const result = num / divider;
      return includeDecimal
        ? (result % 1 === 0 ? result.toFixed(0) : result.toFixed(1)) + suffix
        : Math.floor(result) + suffix;
    };

    if (value < 1000) {
      return value.toString();
    } else if (value < 1000000) {
      return abbreviate(value, 1000, 'K');
    } else if (value < 1000000000) {
      return abbreviate(value, 1000000, 'M');
    } else {
      return abbreviate(value, 1000000000, 'B');
    }
  }
}
